import React, { Component } from "react"
import Layout from '../components/layout';
import { graphql, Link } from 'gatsby'
import moment from 'moment';
import 'moment/locale/fr';
import { DiscussionEmbed } from "disqus-react";
import MetaTags from '../components/MetaTags.js';

class BlogPost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalWidth: 0,
            showHeaderFixed: false,
            done: false
        }
    }

    componentDidMount() {
        this.setState({ totalWidth: window.innerWidth })
        window.addEventListener('resize', function (event) {
            this.setState({ totalWidth: window.innerWidth })
        }.bind(this));
        window.addEventListener('scroll', function (event) {
            if (window.scrollY > 150 && this.state.showHeaderFixed === false) this.setState({ showHeaderFixed: true })
            else if (window.scrollY <= 150 && this.state.showHeaderFixed === true) this.setState({ showHeaderFixed: false })
        }.bind(this));
        this.setState({ done: true })
        setTimeout(function () {
            var ele = document.getElementById('ehader-ee')
            ele.style.opacity = 1
        }, 250)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', function (event) {
            if (window.scrollY > 150 && this.state.showHeaderFixed === false) this.setState({ showHeaderFixed: true })
            else if (window.scrollY <= 150 && this.state.showHeaderFixed === true) this.setState({ showHeaderFixed: false })
        }.bind(this));
    }

    getHeight() {
        var h = 930
        if (this.state.totalWidth < 1200) h = 850
        if (this.state.totalWidth < 900) h = 750
        if (this.state.totalWidth < 768) h = 700
        return h
    }

    isNotFr(window) {
        if (typeof window !== 'undefined') {
            if (window.location.pathname.includes("/en/") === true) return true
            if (window.location.pathname.includes("/de/") === true) return true
            if (window.location.pathname.includes("/it/") === true) return true
            if (window.location.pathname.includes("/es/") === true) return true
        }
        return false
    }

    getLang(window) {
        if (typeof window !== 'undefined') {
            if (window.location.pathname.includes("/en/") === true) return 'en'
            if (window.location.pathname.includes("/de/") === true) return 'de'
            if (window.location.pathname.includes("/it/") === true) return 'it'
            if (window.location.pathname.includes("/es/") === true) return 'es'
        }
        return 'fr'
    }

    render() {
        var post = this.props.data.blogPostUnique
        moment.locale(post.lang)
        // console.log(this.props)
        // const url_post = this.props.data.site.siteMetadata.siteUrl
        const disqusShortname = "blog-sommelierduparfum-com";
        const disqusConfig = {
            identifier: post.slug,
            title: post.title
        }
        var txt = {
            'en': {
                'publish': "Published on",
                "update": "Modified on",
                "discover": "Discover my perfume",
                "by": "By",
                "prev": "Previous article",
                "next": "Next article"
            },
            'de': {
                'publish': "Veröffentlicht am",
                "update": "Geändert am",
                "discover": "Mein Parfum entdecken",
                "by": "Von",
                "prev": "Vorheriger Artikel",
                "next": "Nächster Artikel"
            },
            'fr': {
                'publish': "Publié le",
                "update": "Modifié le",
                "discover": "Découvrir mon parfum",
                "by": "Par",
                "prev": "Article précédent",
                "next": "Article suivant"
            },
            'it': {
                'publish': "Pubblicato su",
                "update": "Modificato su",
                "discover": "Scoprite il mio profumo",
                "by": "Da",
                "prev": "Articolo precedente",
                "next": "Articolo successivo"
            },
            'es': {
                'publish': "Publicado el",
                "update": "Modificado el",
                "discover": "Descubrir mi fragancia",
                "by": "Por",
                "prev": "Artículo anterior",
                "next": "Artículo siguiente"
            }
        }
        var textDatePublish = txt[post.lang]['publish']
        if (post.date !== post.date_update) {
            post.date = post.date_update
            textDatePublish = txt[post.lang]['update']
        }
        var pathImage = "/../images"
        if (["en", "de", "it", "es"].indexOf(post.lang) !== -1) pathImage = "/../../images"
        return (
            <Layout>
                <MetaTags
                    title={post.title}
                    description={post.description}
                    thumbnail={post.image_header}
                    // url={url_post}
                    pathname={this.props.location.href}
                    keywords={post.tags}
                    dateCreation={post.date}
                    dateUpdate={post.date_update}
                />
                <div id="ehader-ee" className=" transition-300 new-header-top-btn-fixed" style={{ top: this.state.showHeaderFixed === true ? 0 : -61, width: this.state.totalWidth }}>
                    {this.state.totalWidth > 900 ?
                        <div>
                            <div onClick={() => this.getLang(window) === 'fr' ? window.location = "/" : window.location = "/" + this.getLang(window) + "/"} onKeyDown={() => console.log('')}
                                style={{ cursor: 'pointer', position: "relative", height: 46, width: 320, margin: "auto" }} className="btn-home-hide-little">
                                {/* <img alt='header_sommelier_blog' src={siteHeader} style={{ height: "100%", width: "auto" }} /> */}
                                <div style={{ textAlign: "center", width: "100%", fontFamily: "SB", fontSize: 18, top: 16, position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
                                    SOMMELIER DU PARFUM <span style={{ fontFamily: "SBLight", fontStyle: "italic" }}>Blog</span>
                                </div>
                            </div>
                            <a rel="noopener noreferrer" href="https://sommelierduparfum.com" target='_blank' className="" style={{
                                fontFamily: 'FuturaBook', textDecoration: "none", color: "#eee", padding: "14px 28px", textTransform: "uppercase",
                                fontSize: 14, letterSpacing: "0.03em", display: 'inline-flex', backgroundColor: "#000", position: 'absolute', top: 5, right: "calc(7% + 15px)"
                            }}>
                                {txt[post.lang]['discover']}
                            </a>
                        </div>
                        :
                        <a rel="noopener noreferrer" href="https://sommelierduparfum.com" target='_blank' className="" style={{
                            fontFamily: 'FuturaBook', textDecoration: "none", color: "#eee", padding: "12px 28px", textTransform: "uppercase",
                            fontSize: 14, letterSpacing: "0.03em", display: 'inline-flex', backgroundColor: "#000", marginTop: 8
                        }}>
                            {txt[post.lang]['discover']}
                        </a>
                    }
                </div>
                <div style={{ width: this.state.totalWidth, minWidth: this.state.totalWidth, maxWidth: this.state.totalWidth, height: this.getHeight(), overflow: "hidden", zIndex: -1, position: "absolute", top: -10, left: 0 }}>
                    <div className="image-bg-blogpost" style={{ width: this.state.totalWidth + 20, minWidth: this.state.totalWidth + 20, height: this.getHeight(), zIndex: -1, backgroundImage: "url(" + post.image_header + ')' }}>
                        <div className="bg-coer-topbig"></div>
                    </div>
                    {/* <img src={post.image_header} alt={'image-header-' + post.slug} id='refTop' className='image-bg-blogpost'
                        style={{  minWidth: "calc(100% + 20px)", height: this.getHeight(), zIndex: -1 }} /> */}
                </div>
                <div className='BlogPostFont' style={{ position: "relative" }}>
                    <div className="BlogTitle">
                        <h1 style={{ marginBottom: 15, marginTop: this.state.totalWidth < 1200 ? 0 : 25 }}>{post.title}</h1>
                        <h2 className="BlogEntete" style={{ marginBottom: 15, marginTop: 0 }}>{post.description}</h2>
                        <div style={{ display: "inline-flex", /* height: 54, */ margin: "10px 0 0px 0" }}>
                            <div style={{ paddingTop: 2 }}>
                                <div style={{ fontSize: "16px", lineHeight: "25px", color: "#000", fontFamily: "FuturaLight", letterSpacing: "-0.1px", display: 'inline-flex' }}>
                                    {textDatePublish}
                                    <div style={{ fontFamily: "FuturaBook", marginLeft: 4 }}>
                                        {post.lang === "fr" ? moment(post.date).format("Do MMMM YYYY") : moment(post.date).format("MMMM Do YYYY")/*  + " · " + post.reading + ' min de lecture' */}
                                    </div>
                                </div>
                                <br />
                                <div style={{ fontSize: "16px", lineHeight: "25px", color: "#000", fontFamily: "FuturaLight", letterSpacing: ".2px", display: 'inline-flex' }}>
                                    {txt[post.lang]['by']}
                                    <div style={{ fontFamily: "FuturaBook", marginLeft: 4 }}>
                                        {post.author}
                                    </div>
                                </div>
                                <br />
                                <div style={{ fontSize: "14px", lineHeight: "20px", color: "#444", fontFamily: "FuturaLight", letterSpacing: ".2px", display: 'inline-flex' }}>
                                    {post.credit_cover}
                                </div>
                            </div>
                        </div>
                    </div>
                    {post.image_header === null ? null :
                        <div className="imageHeaderDivsize" style={{}}>
                            <img src={post.image_header} alt={'image-header-' + post.slug} className='imageHeaderWithoutMargin' style={{ width: "100%", marginBottom: 0 }} />
                            {/* <div className="blackUnderImage" style={{ height: "10px", margin: "-7px auto auto auto", background: "black", "width": "80%", display: "block" }}></div> */}
                        </div>
                    }
                    <div className="BlogContent">
                        <div className="firstLetter" style={{ marginTop: "45px" }} dangerouslySetInnerHTML={{ __html: post.content_html.replace(/_self/g, '_blank') }} />
                    </div>
                    <div className="list-article-bottom screen-artcile-titel" >
                        <div className={"div-blog-landing next-width-blog-bottom next-article"} style={{ display: 'block', paddingBottom: 0 }}>
                            {post.prev_image_header === "" ? null :
                                <Link to={"/" + post.prev_slug} style={{ textDecoration: "none" }} className="link" >
                                    <img alt="" src={pathImage + "/arrow_big.png"} style={{ height: 12, width: 'auto' }} className="img-right-next-article" />
                                    {txt[post.lang]['prev']}
                                </Link>
                            }
                        </div>
                        <div className='between-width-blog-bottom'>
                        </div>
                        <div className={"div-blog-landing next-width-blog-bottom next-article"} style={{ textAlign: "end", display: 'block', paddingBottom: 0 }}>
                            {post.next_image_header === "" ? null :
                                <Link to={"/" + post.next_slug} style={{ textDecoration: "none" }} className="link" >
                                    {txt[post.lang]['next']}
                                    <img alt="" src={pathImage + "/arrow_big.png"} style={{ height: 12, width: 'auto', transform: "rotate(180deg)" }} className="img-left-next-article" />
                                </Link>
                            }
                        </div>
                    </div>
                    <div className="list-article-bottom">
                        <div className={"div-blog-landing next-width-blog-bottom next-article screen-artcile-titel-mobile"} style={{ display: 'block', paddingBottom: 0 }}>
                            {post.prev_image_header === "" ? null :
                                <Link to={"/" + post.prev_slug} style={{ textDecoration: "none" }} className="link" >
                                    <img alt="" src={pathImage + "/arrow_big.png"} style={{ height: 12, width: 'auto' }} className="img-right-next-article" />
                                    {txt[post.lang]['discover']}
                                </Link>
                            }
                        </div>
                        <div className={"div-blog-landing next-width-blog-bottom"}>
                            {post.prev_image_header === "" ? null :
                                <Link to={"/" + post.prev_slug} style={{ textDecoration: "none" }} className="link" >
                                    <div className={"div-blog-landing-image"}>
                                        <img src={post.prev_image_header} className="banner-blog-landing" alt={'image_header_prev_little'} />
                                    </div>
                                    <div className={"content-blog-landing next-width-content-blog-landing"}>
                                        <div className="hover-black-border"></div>
                                        <div className={"title-blog-landing"}>
                                            {post.prev_title}
                                        </div>
                                        <div className={"desc-blog-landing"}>
                                            {post.prev_description}
                                        </div>
                                    </div>
                                </Link>
                            }
                        </div>
                        <div className='between-width-blog-bottom'>
                        </div>
                        <div className={"div-blog-landing next-width-blog-bottom next-article screen-artcile-titel-mobile"} style={{ textAlign: "end", display: 'block', paddingBottom: 0 }}>
                            {post.next_image_header === "" ? null :
                                <Link to={"/" + post.next_slug} style={{ textDecoration: "none" }} className="link" >
                                    {txt[post.lang]['next']}
                                    <img alt="" src={pathImage + "/arrow_big.png"} style={{ height: 12, width: 'auto', transform: "rotate(180deg)" }} className="img-left-next-article" />
                                </Link>
                            }
                        </div>
                        <div className={"div-blog-landing next-width-blog-bottom"}>
                            {post.next_image_header === "" ? null :
                                <Link to={"/" + post.next_slug} style={{ textDecoration: "none" }} className="link" >
                                    <div className={"div-blog-landing-image"}>
                                        <img src={post.next_image_header} className="banner-blog-landing" alt={'image_header_next_little'} />
                                    </div>
                                    <div className={"content-blog-landing next-width-content-blog-landing"}>
                                        <div className="hover-black-border"></div>
                                        <div className={"title-blog-landing"}>
                                            {post.next_title}
                                        </div>
                                        <div className={"desc-blog-landing"}>
                                            {post.next_description}
                                        </div>
                                    </div>
                                </Link>
                            }
                        </div>
                    </div>
                    <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
                </div>
            </Layout>
        )
    }
}

export default BlogPost;

export const query = graphql`
  query allBlogPostUnique($slug: String!) {
    blogPostUnique(slug: { eq: $slug }) {
      title
      description
      tags
      slug
      content_html
      image_header
      credit_cover
      author
      lang
      reading
      date
      date_update
      prev_title
      prev_description
      prev_image_header
      prev_slug
      prev_lang
      next_title
      next_description
      next_image_header
      next_slug
      next_lang
    }
  }
`
